import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import LoadingSpinner from "../../../components/loading-spinner";
import Modal from "../../../components/modal";
import {
    clear_section,
    get_all_section,
    searchItemSection,
    UPDATE_SECTION,
    update_section
} from "../../../redux/sections";
import TextArea from "../../../components/text-area";
import CheckBox from "../../../components/check-box";
import {create_group, clear_group, CREATE_GROUP} from "../../../redux/groups";
import {get_all_groups} from "../../../redux/users";
import Select from "react-select";

const UpdateSection = () => {
    let history = useHistory();
    const {id} = useParams();

    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _update_section = useSelector((state) => state.section_module._update_section);
    const _create_group = useSelector((state) => state.group_module._create_group);
    const _get_all_groups = useSelector((state) => state.users_module._get_all_groups);

    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [roles, setRoles] = useState([]);
    const [roles_select, setRolesSelect] = useState([]);
    const [options_roles, setOptionsRoles] = useState([]);

    const [form, setForm] = useState({
        titulo: "",
        subtitulo: "",
        descripcion: "",
        posicion: 0,
        activo: true,
        grupos: [],
        roles: []
    });

    const [data, setData] = useState({
        id_seccion: id,
        posicion: 0,
        multiple: false,
        requerido: false,
    });

    useEffect(() => {
        // eslint-disable-next-line
        getItemSection();
        // eslint-disable-next-line
    }, [token, id]);

    useEffect(() => {
        dispatch(get_all_groups(token));
    }, [token, dispatch]);

    useEffect(() => {
        if (_get_all_groups && _get_all_groups.data && _get_all_groups.data.datos) {
            setRoles(_get_all_groups.data.datos);
            let voptions = _get_all_groups.data.datos.map((item) => {
                return {
                    value: item.id,
                    label: item.nombre,
                };
            });
            voptions.unshift({
                value: "ALL",
                label: "TODOS",
            })

            setOptionsRoles(voptions);
        }
    }, [_get_all_groups]);

    const handleChangeSelect = (e) => {
        let all = e.filter(x => x.value === "ALL")
        if (all.length > 0) {
            setRolesSelect(all)
        } else {
            setRolesSelect(e);
        }
    };

    async function getItemSection() {
        setIsLoading(true);
        try {
            const response = await searchItemSection(token, id);
            if (response && response.data && response.data.datos) {
                let v = response.data.datos;
                setForm({
                    titulo: v.titulo,
                    subtitulo: v.subtitulo,
                    descripcion: v.descripcion,
                    posicion: v.posicion,
                    activo: v.activo,
                    grupos: v.grupos,
                    roles: v.roles
                })
                let vroles = v.roles.map((x) => {
                    return {
                        value: x.id,
                        label: x.nombre
                    };
                })
                setRolesSelect(vroles);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    const handleChange = (e) => {
        if (e.target.name === 'activo') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    useEffect(() => {
        if (_update_section.status === 200) {
            dispatch(clear_section(UPDATE_SECTION));
            dispatch(get_all_section(token));
            setIsLoading(false);
            history.push("/secciones");
        } else {
            if (_update_section.status !== 0) {
                toast.error(_update_section.message);
                setIsLoading(false);
            }
        }
    }, [_update_section, dispatch, history, token]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        let all = roles_select.filter(x => x.value === "ALL")

        if (all.length > 0) {
           let array = roles.map((item) => {
                return {
                    id: item.id,
                };
            });
            form.roles = array;

        } else {
            let array = roles_select.map((item) => {
                return {
                    id: item.value,
                };
            });
            form.roles = array;
        }

        if (form.titulo === "") {
            toast.error("Debe ingresar el título", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.posicion === 0) {
            toast.error("Debe ingresar la posición de la sección", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.roles.length === 0) {
            toast.error("Debe seleccionar al menos un grupo de permiso");
            setIsLoading(false);
            return;
        }

        dispatch(update_section(token, id, form));
    };

    const handleChangeData = (e) => {
        if (e.target.name === 'multiple' || e.target.name === 'requerido') {
            setData({
                ...data,
                [e.target.name]: e.target.checked,
            });
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value,
            });
        }
    };

    useEffect(() => {
        if (_create_group.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_group(CREATE_GROUP));
            getItemSection();
            setIsLoading(false);
            setModal(false);
            history.push(`/seccion/grupo/${id}/${_create_group.data.datos.id}`);
        } else if (_create_group.status !== 0) {
            dispatch(clear_group(CREATE_GROUP));
            toast.error(_create_group.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [_create_group, dispatch, history, token, id]);

    const handleSubmitGroup = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (data.posicion === 0) {
            toast.error("Debe ingresar la posición del grupo", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(create_group(token, data));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <div><ToastContainer/></div>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Formulario de empleados"
                            items={[{label: "Confguración de datos", link: "/secciones"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Sección</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="titulo"
                                                label="Título"
                                                value={form.titulo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Subtítulo"
                                                name="subtitulo"
                                                value={form.subtitulo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <TextArea
                                                name="descripcion"
                                                label="Descripción"
                                                placeholder=""
                                                value={form.descripcion}
                                                onChange={handleChange}
                                                rows={2}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                label="Posición"
                                                name="posicion"
                                                type="number"
                                                value={form.posicion}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Grupo de Permisos</label>
                                                <Select
                                                    options={options_roles}
                                                    isMulti={true}
                                                    onChange={handleChangeSelect}
                                                    value={roles_select}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <CheckBox label="Activo" name="activo" id="activo" onChange={handleChange}
                                                      state={form.activo}/>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 col-12">
                                            <h5>Grupos agregados</h5>
                                        </div>
                                        <div className="col-md-6 col-12 text-right">
                                            <Button type="button" text="Agregar grupo" theme="secondary" classes="mb-1"
                                                    onChange={() => {
                                                        setData({
                                                            id_seccion: id,
                                                            posicion: 0,
                                                            multiple: false,
                                                            requerido: false,
                                                        });
                                                        setModal(true);
                                                    }}/>
                                        </div>
                                        {(form.grupos && form.grupos.length > 0) &&
                                            form.grupos.map((g) =>
                                                <div className="col-12" style={{padding: "0 2em"}}>
                                                    <div className="row border_card">
                                                        <div className="col-md-3">
                                                            <span>Posición</span>
                                                            <p className="border_card">{g.posicion}</p>
                                                        </div>
                                                        <div className="col-md-3 mt-2">
                                                            <div className="d-flex">
                                                                <p style={{marginTop: 5}}>Es múltiple: </p>

                                                                {g.multiple ?
                                                                    <div><i className="bx bx-check-square" style={{fontSize: '2em'}}/></div>
                                                                    :
                                                                    <div><i className="bx bx-checkbox" style={{fontSize: '2em'}}/></div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 mt-2">
                                                            <div className="d-flex">
                                                                <p style={{marginTop: 5}}>Es requerido: </p>

                                                                {g.requerido ?
                                                                    <div><i className="bx bx-check-square" style={{fontSize: '2em'}}/></div>
                                                                    :
                                                                    <div><i className="bx bx-checkbox" style={{fontSize: '2em'}}/></div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 mt-2">
                                                            <div className="d-flex">
                                                                <p style={{marginTop: 5}}>Activo: </p>

                                                                {g.activo ?
                                                                    <div><i className="bx bx-check-square" style={{fontSize: '2em'}}/></div>
                                                                    :
                                                                    <div><i className="bx bx-checkbox" style={{fontSize: '2em'}}/></div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {(g.campos && g.campos.length > 0) &&
                                                            g.campos.map(c => (
                                                                <div className="col-md-3">
                                                                    <span>Campo</span>
                                                                    <p className="border_card">{c.nombre}</p>
                                                                </div>
                                                            ))
                                                        }
                                                        <div className="col-12">
                                                            <Button type="button" text="Editar grupo" theme="secondary"
                                                                    classes="mb-1"
                                                                    onChange={() => {
                                                                        history.push(`/seccion/grupo/${id}/${g.id}`);
                                                                    }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="card-footer d-flex justify-content-end">
                                        <Link to="/secciones" className="btn btn-danger m-1">
                                            Cancelar
                                        </Link>
                                        <Button
                                            type="submit"
                                            text={
                                                <>
                                                    <i className="bx bx-save"/> Guardar
                                                </>
                                            }
                                            theme="success"
                                            disabled={isLoading}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleSubmitGroup}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Crear grupo</h5>
                        <div className="form-group">
                            <Input
                                label="Posición"
                                name="posicion"
                                type="number"
                                value={data.posicion}
                                onChange={handleChangeData}
                            />
                        </div>
                        <div className="form-group">
                            <CheckBox label="Multiple" name="multiple" id="multiple" onChange={handleChangeData}
                                      state={data.multiple}/>
                        </div>
                        <div className="form-group">
                            <CheckBox label="Requerido" name="requerido" id="requerido" onChange={handleChangeData}
                                      state={data.requerido}/>
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-outline-dark" onClick={() => {
                            setModal(false);
                        }}> Cancelar
                        </button>
                        <Button type="submit" text="Guardar" theme="success" classes="m-1"/>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default UpdateSection;
