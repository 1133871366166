import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams, useHistory} from "react-router-dom";
import {
    searchEmployeeForm, 
    update_form, 
    UPDATE_FORM, 
    get_all_employee_form,
    clear_status_form
} from "../../../redux/forms";
import LoadingSpinner from '../../../components/loading-spinner';
import {URLAPI} from "../../../config/index";
import Button from "../../../components/button";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../../../components/modal";
import TextArea from "../../../components/text-area";

const ItemDatas = (props) => {
    const {id} = useParams();

    const dispatch = useDispatch();
    let history = useHistory();

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _update_form = useSelector((state) => state.form_module._update_form);

    const [isLoading, setIsLoading] = useState(false);
    const [modal_aprobar, setModalAprobar] = useState(false);
    const [modal_rechazar, setModalRechazar] = useState(false);
    const [comentario, setComentario] = useState('');
    const [data, setData] = useState({});
    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    async function getEmployeeForm() {
        setIsLoading(true);
        const params = '?id_empleado=' + id;
        try {
            const response = await searchEmployeeForm(token, params);
            if (response && response.data && response.data.datos) {
                setData(response.data.datos);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getEmployeeForm();
        // eslint-disable-next-line
    }, [token, id]);

    useEffect(() => {
        if (_update_form.status === 200) {
            dispatch(clear_status_form(UPDATE_FORM));
            dispatch(get_all_employee_form(token));
            setIsLoading(false);
            setModalAprobar(false);
            setModalRechazar(false);
            history.push("/empleados-formularios");
        } else {
            if (_update_form.status !== 0) {
                toast.error(_update_form.message);
                setIsLoading(false);
            }
        }
    }, [_update_form, dispatch, history, token]);
    

    function update_state(value) {
        setIsLoading(true);
        let va = {'tipo': value}
        if (value === 'rechazar'){
            if (comentario === "") {
                toast.error("Debe ingresar un comentario");
                setIsLoading(false);
                return;
            }
            va = {'tipo': value, 'comentario': comentario}
        }

        dispatch(update_form(token, data.id_formulario, va));
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <div><ToastContainer/></div>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Formulario empleados"
                            items={[{label: "Verificación de datos", link: "/empleados-formularios"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <p>{data.valido}</p>
                                {data &&
                                    data.valido === true ?
                                    <>
                                        <div className="row">
                                            <div className="col-12 text-right">
                                                <h5>{data.estado}</h5>
                                            </div>
                                            <div className="col-md-8 col-12">
                                                <h5>Tipo de formulario: {data.tipo}</h5>
                                            </div>
                                            <div className="col-md-4 col-12 text-right">
                                                <a href={URLAPI + data.firma} target="_blank" style={{textDecoration: 'underline'}}>Descargar archivo firmado</a>
                                            </div>
                                            {data.datos.map(x => 
                                                <div className="col-12 mb-2">
                                                    <h5>{x.titulo}</h5>
                                                    {x.grupos.map(g => 
                                                        g.campos.map(c => 
                                                            <div className="row pt-1 pb-1 mb-1" style={{border: '2px solid #BDBDBD', margin: '0 5px'}}>
                                                                {c.map(f => 
                                                                    <div className="col-md-6 col-12 mt-1">
                                                                        <label>{f.nombre}</label>
                                                                        <input class="form-control" value={f.valor}/>
                                                                    </div>
                                                                )}
                                                            </div>   
                                                        )
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                        <div className="card-footer d-flex justify-content-end">
                                            <Link to="/formularios-empleados" className="btn btn-danger m-1">
                                                Cancelar
                                            </Link>
                                            { permisos &&
                                                permisos.filter(x => (x.codigo === 'change_forms').length > 0) &&
                                                data.estado === 'Completo' &&
                                                    <>
                                                        <Button
                                                            type="button"
                                                            text="Rechazar"
                                                            onChange={() => {
                                                                setModalRechazar(true);
                                                                setComentario('');
                                                            }}
                                                            disabled={isLoading}
                                                            className="m-md-1 btn btn-secondary"
                                                        />
                                                        <Button
                                                            type="button"
                                                            text="Aprobar"
                                                            theme="success"
                                                            onChange={() => {setModalAprobar(true);}}
                                                            disabled={isLoading}
                                                        />
                                                    </>
                                                
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className="text-center">
                                        <h5>{data.mensaje}</h5>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal_aprobar}>
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="text-center">
                            <img
                                className="img-fluid"
                                src={
                                    window.location.origin +
                                    "/base/app-assets/images/icon/help.png"
                                }
                                alt="branding logo"
                            />
                            <h5 className="mt-2">¿Desea marcar el formulario como aprobado?. Esta acción no se puede deshacer</h5>
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-outline-dark" onClick={() => {
                            setModalAprobar(false)
                        }}>
                            Cancelar
                        </button>
                        <button type="button" className="btn btn-dark m-1" onClick={() => {
                            update_state('aprobar')
                        }} disabled={isLoading}>
                            Confirmar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal className="modal-main div-modal" show={modal_rechazar}>
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="text-center">
                            <img
                                className="img-fluid"
                                src={
                                    window.location.origin +
                                    "/base/app-assets/images/icon/help.png"
                                }
                                alt="branding logo"
                            />
                            <h5 className="mt-2">Rechazar formulario. Por favor ingrese un comentario por el cual se rechaza el formulario</h5>
                        </div>
                        <dv>
                            <TextArea
                                id="comentario"
                                name="comentario"
                                label="Comentario"
                                placeholder=""
                                value={comentario}
                                onChange={(e) => {setComentario(e.target.value)}}
                                rows={4}
                            />
                        </dv>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-outline-dark" onClick={() => {
                            setModalRechazar(false)
                        }}>
                            Cancelar
                        </button>
                        <button type="button" className="btn btn-dark m-1" onClick={() => {
                            update_state('rechazar')
                        }} disabled={isLoading}>
                            Guardar
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ItemDatas;