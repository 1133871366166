import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import LoadingSpinner from '../../../components/loading-spinner';
import {
    clear_section,
    CREATE_SECTION,
    get_all_section,
    create_section
} from "../../../redux/sections";
import TextArea from "../../../components/text-area";
import {get_all_groups} from "../../../redux/users";
import Select from "react-select";

const Sections = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_section = useSelector((state) => state.section_module._get_all_section);
    const _create_section = useSelector((state) => state.section_module._create_section);
    const _get_all_groups = useSelector((state) => state.users_module._get_all_groups);

    const [data, setData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rolesOptions, setRolesOptions] = useState([]);
    const [roles_select, setRolesSelect] = useState([]);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        dispatch(get_all_section(token));
    }, [token, dispatch]);

    useEffect(() => {
        dispatch(get_all_groups(token));
    }, [token, dispatch]);

    useEffect(() => {
        if (_get_all_section && _get_all_section.data && _get_all_section.data.datos) {
            setData(_get_all_section.data.datos);
        }
    }, [_get_all_section]);

    useEffect(() => {
        if (_get_all_groups && _get_all_groups.data && _get_all_groups.data.datos) {
            setRoles(_get_all_groups.data.datos);
            let options = _get_all_groups.data.datos.map((item) => {
                return {
                    value: item.id,
                    label: item.nombre,
                };
            });
            options.unshift({
                value: "ALL",
                label: "TODOS",
            })
            setRolesOptions(options);
        }
    }, [_get_all_groups]);

    const handleChangeSelect = (e) => {
        let all = e.filter(x => x.value === "ALL")
        if (all.length > 0) {
            setRolesSelect(all)
        } else {
            setRolesSelect(e);
        }
    };

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [form, setForm] = useState({
        titulo: "",
        subtitulo: "",
        descripcion: "",
        posicion: 0,
        roles: []
    });

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_sections').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "calendar",
                    onClick: (item) => {
                        history.push(`/seccion/detalle/${item.id}`);
                    },
                })
            }
        }
        setActions(act);
        // eslint-disable-next-line
    }, [permisos, history]);

    useEffect(() => {
        if (_create_section.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_section(CREATE_SECTION));
            dispatch(get_all_section(token));
            setIsLoading(false);
            setModal(false);
            history.push(`/seccion/detalle/${_create_section.data.datos.id}`);
        } else if (_create_section.status !== 0) {
            dispatch(clear_section(CREATE_SECTION));
            toast.error(_create_section.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_section, dispatch, history, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        let all = roles_select.filter(x => x.value === "ALL")

        if (all.length > 0) {
           let array = roles.map((item) => {
                return {
                    id: item.id,
                };
            });
            form.roles = array;

        } else {
            let array = roles_select.map((item) => {
                return {
                    id: item.value,
                };
            });
            form.roles = array;
        }

        if (form.titulo === "") {
            toast.error("Debe ingresar el título", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.posicion === 0) {
            toast.error("Debe ingresar la posición de la sección", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (form.roles.length === 0) {
            toast.error("Debe seleccionar al menos un grupo de permiso");
            setIsLoading(false);
            return;
        }

        dispatch(create_section(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <div><ToastContainer/></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Formulario de empleados"
                            items={[{label: "Confguración de datos", link: "/secciones"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_sections').length > 0 ?
                                        <Button type="button" text="Crear" theme="success" onClick={() => {
                                            setModal(true);
                                            setForm({...form, titulo: '', subtitulo: '', descripcion: '', posicion: 0, roles: []});
                                            setRolesSelect([]);
                                        }}/> : null
                                }
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "titulo",
                                                label: "Sección",
                                                type: "text",
                                            },
                                            {
                                                name: "posicion",
                                                label: "Posición",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleSubmit}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Crear sección</h5>
                        <div className="form-group">
                            <Input
                                label="Título"
                                name="titulo"
                                value={form.titulo}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                label="Subtítulo"
                                name="subtitulo"
                                value={form.subtitulo}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <TextArea
                                name="descripcion"
                                label="Descripción"
                                placeholder=""
                                value={form.descripcion}
                                onChange={handleChange}
                                rows={2}
                            />
                        </div>
                        <div className="form-group">
                            <Input
                                label="Posición"
                                name="posicion"
                                type="number"
                                value={form.posicion}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Grupo de Permisos</label>
                            <Select
                                options={rolesOptions}
                                isMulti={true}
                                onChange={handleChangeSelect}
                                value={roles_select}
                            />
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-outline-dark" onClick={() => {
                            setModal(false);
                        }}> Cancelar
                        </button>
                        <Button type="submit" text="Guardar" theme="success" classes="m-1"/>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default Sections;
