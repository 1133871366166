import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    get_all_employee_form
} from '../../../redux/forms';

const EmployeesForms = (props) => {
    let dispatch = useDispatch();
    let history = useHistory();

    const _get_all_employee_form = useSelector((state) => state.form_module._get_all_employee_form);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(get_all_employee_form(token));
    }, [token, dispatch]);

    useEffect(() => {
        if (_get_all_employee_form && _get_all_employee_form.data && _get_all_employee_form.data.datos) {
            setData(_get_all_employee_form.data.datos);
        }
    }, [_get_all_employee_form]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'view_employees').length > 0) {
                act.push({
                    name: "view",
                    label: "Ver",
                    icon: "bx bx-show",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/empleado/datos/${item.id}`);
                    },
                })
            }
        }
        setActions(act);
        // eslint-disable-next-line
    }, [permisos, history]);


    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Formulario empleados"
                            items={[{label: "Verificación de datos", link: "/empleados-formularios"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "empleado",
                                                label: "Empleado",
                                                type: "text",
                                            },
                                            {
                                                name: "ruc",
                                                label: "Ruc",
                                                type: "text",
                                            },
                                            {
                                                name: "fecha",
                                                label: "Fecha de actualización",
                                                type: "text",
                                            },
                                            {
                                                name: "estado",
                                                label: "Estado",
                                                type: "text",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeesForms;
